@font-face {
  font-family: 'Saira Extra Condensed';
  src: url('./fonts/SairaExtraCondensed-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Saira Extra Condensed Thin';
  src: url('./fonts/SairaExtraCondensed-Thin.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Saira Extra Condensed Extra Light';
  src: url('./fonts/SairaExtraCondensed-ExtraLight.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Saira Extra Condensed Bold';
  src: url('./fonts/SairaExtraCondensed-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}
