@font-face {
  font-family: 'Mid Century Sans';
  src: url('./fonts/MidCenturySans-Bold.woff2') format('woff2'),
    url('./fonts/MidCenturySans-Bold.woff') format('woff'),
    url('./fonts/MidCenturySans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans';
  src: url('./fonts/MidCenturySans-BoldIt.woff2') format('woff2'),
    url('./fonts/MidCenturySans-BoldIt.woff') format('woff'),
    url('./fonts/MidCenturySans-BoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans';
  src: url('./fonts/MidCenturySans-Heavy.woff2') format('woff2'),
    url('./fonts/MidCenturySans-Heavy.woff') format('woff'),
    url('./fonts/MidCenturySans-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans';
  src: url('./fonts/MidCenturySans-HeavyIt.woff2') format('woff2'),
    url('./fonts/MidCenturySans-HeavyIt.woff') format('woff'),
    url('./fonts/MidCenturySans-HeavyIt.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans';
  src: url('./fonts/MidCenturySans-Italic.woff2') format('woff2'),
    url('./fonts/MidCenturySans-Italic.woff') format('woff'),
    url('./fonts/MidCenturySans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans';
  src: url('./fonts/MidCenturySans-ThinIt.woff2') format('woff2'),
    url('./fonts/MidCenturySans-ThinIt.woff') format('woff'),
    url('./fonts/MidCenturySans-ThinIt.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans';
  src: url('./fonts/MidCenturySans-LightIt.woff2') format('woff2'),
    url('./fonts/MidCenturySans-LightIt.woff') format('woff'),
    url('./fonts/MidCenturySans-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans Bold';
  src: url('./fonts/MidCenturySans-Bold.woff2') format('woff2'),
    url('./fonts/MidCenturySans-Bold.woff') format('woff'),
    url('./fonts/MidCenturySans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans SmBold';
  src: url('./fonts/MidCenturySans-SmBold.woff2') format('woff2'),
    url('./fonts/MidCenturySans-SmBold.woff') format('woff'),
    url('./fonts/MidCenturySans-SmBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans XBold';
  src: url('./fonts/MidCenturySans-XBold.woff2') format('woff2'),
    url('./fonts/MidCenturySans-XBold.woff') format('woff'),
    url('./fonts/MidCenturySans-XBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans Heavy';
  src: url('./fonts/MidCenturySans-Heavy.woff2') format('woff2'),
    url('./fonts/MidCenturySans-Heavy.woff') format('woff'),
    url('./fonts/MidCenturySans-Heavy.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans';
  src: url('./fonts/MidCenturySans-Light.woff2') format('woff2'),
    url('./fonts/MidCenturySans-Light.woff') format('woff'),
    url('./fonts/MidCenturySans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans';
  src: url('./fonts/MidCenturySans-Thin.woff2') format('woff2'),
    url('./fonts/MidCenturySans-Thin.woff') format('woff'),
    url('./fonts/MidCenturySans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans XBold';
  src: url('./fonts/MidCenturySans-XBoldIt.woff2') format('woff2'),
    url('./fonts/MidCenturySans-XBoldIt.woff') format('woff'),
    url('./fonts/MidCenturySans-XBoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans Thin';
  src: url('./fonts/MidCenturySans-Thin.woff2') format('woff2'),
    url('./fonts/MidCenturySans-Thin.woff') format('woff'),
    url('./fonts/MidCenturySans-Thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans XLight';
  src: url('./fonts/MidCenturySans-XLight.woff2') format('woff2'),
    url('./fonts/MidCenturySans-XLight.woff') format('woff'),
    url('./fonts/MidCenturySans-XLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans';
  src: url('./fonts/MidCenturySans-Regular.woff2') format('woff2'),
    url('./fonts/MidCenturySans-Regular.woff') format('woff'),
    url('./fonts/MidCenturySans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans SmBold';
  src: url('./fonts/MidCenturySans-SmBoldIt.woff2') format('woff2'),
    url('./fonts/MidCenturySans-SmBoldIt.woff') format('woff'),
    url('./fonts/MidCenturySans-SmBoldIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mid Century Sans XLight';
  src: url('./fonts/MidCenturySans-XLightIt.woff2') format('woff2'),
    url('./fonts/MidCenturySans-XLightIt.woff') format('woff'),
    url('./fonts/MidCenturySans-XLightIt.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
